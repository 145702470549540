import React from 'react';
import { Link } from 'gatsby';

const ProductBenefits = props => {
    return (
        <section className="product-benefits">
            <div style={{ marginBottom: '2em', maxWidth: '75rem', margin: '0 auto' }}>
                <h1 style={{ textAlign: 'center' }}>¿Cómo funciona?</h1>
            </div>
            <div className="product-benefits-group">
                <div className="product-benefit-1-tagline">
                    <strong>Necesitaremos algunos datos básicos</strong>{' '}
                    <p>
                        Información de contacto y dirección de entrega para{' '}
                        <strong>registrar tu pedido</strong> y agilizar la entrega.
                    </p>
                </div>
                <div className="product-benefit-1-image"></div>
                <div className="product-benefit-2-tagline">
                    <strong>Servicio Express</strong>
                    <p>
                        Te avisamos cuando el repartidor va en camino y cuando está en la puerta del
                        domicilio. Te llevamos el botellón lleno (previamente lavado y esterilizado)
                        y nos das tu envase vacío.
                    </p>
                </div>
                <div className="product-benefit-2-image"></div>
                <div className="product-benefit-3-tagline">
                    <strong>Botellón lleno, corazón contento</strong>
                    <p>
                        Nuestros repartidores están listos para colocar el botellón en el{' '}
                        <strong>lugar que tu desees</strong> (cocina, filtro dispensador, oficina,
                        etc). Está incluído en nuestro servicio.
                    </p>
                </div>
                <div className="product-benefit-3-image"></div>
            </div>
        </section>
    );
};

export default ProductBenefits;
