import React from 'react';

const SocialProof = props => {
    return (
        <section className="social-proof">
            <div className="social-proof-icons">
                <h4>
                    Con +25.000 botellones entregados a domicilio en 3 años, conocemos de primera
                    mano el trabajo que implica lavar, desinfectar, llenar y entregar un botellón de
                    agua en 30 minutos.
                </h4>
            </div>
        </section>
    );
};

export default SocialProof;
