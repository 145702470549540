import React, { useReducer } from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Input from '../components/Input';
import Button from '../components/Button';
import Loader from '../components/Loader';

const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const isValidEmail = email => {
    if (email.match(re)) {
        return true;
    }
    return false;
};

const PrimeraRecargapage = () => {
    const [state, setState] = useReducer((s, a) => ({ ...s, ...a }), {
        name: null,
        email: null,
        error: null,
        loading: false,
        resolved: false,
    });

    const addEmailToList = e => {
        e.preventDefault();

        if (state.name && state.email && isValidEmail(state.email)) {
            setState({
                ...state,
                loading: true,
                error: null,
            });
            var myHeaders = new Headers();
            myHeaders.append(
                'Authorization',
                'Bearer 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',
            );
            myHeaders.append('Content-Type', 'application/json');
            const contactListId = '357e50d2-b8cd-4270-935e-3550a65f4d95';

            var raw = JSON.stringify({
                list_ids: [contactListId],
                contacts: [{ first_name: state.name.split(' ')[0], email: state.email }],
            });

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow',
            };

            fetch('https://api.sendgrid.com/v3/marketing/contacts', requestOptions)
                .then(response => response.text())
                .then(result => {
                    setState({
                        ...state,
                        loading: false,
                        resolved: true,
                        error: null,
                    });
                })
                .catch(error => {
                    setState({
                        ...state,
                        loading: false,
                        resolved: false,
                        error,
                    });
                });
        } else {
            // show email error
            setState({
                ...state,
                error: 'Completa los campos de nombre y email para continuar.',
            });
        }
    };
    return (
        <Layout>
            <SEO
                title="Aguaman | SeoCONF 2024"
                pathname="/seoconf2024"
                description="Recarga tu botellón sin complicaciones"
                image="https://seoconf2024.com/wp-content/uploads/2022/01/LOGO-SEO-CONF_Mesa-de-trabajo-1-.png"
            />
            <section
                className="testimonials primera-recarga-testimonials"
                style={{
                    backgroundColor: 'white',
                    marginTop: 24,
                    marginBottom: 0,
                    paddingBottom: 0,
                }}
            >
                <div style={{ marginTop: 8 }}>
                    <p style={{ marginBottom: 8, color: 'gray' }}>Encargados de hidratar la</p>
                    <img
                        src="https://seoconf2024.com/wp-content/uploads/2022/01/LOGO-SEO-CONF_Mesa-de-trabajo-1-.png"
                        height={80}
                    />
                </div>
            </section>
            <section className="value-prop primera-recarga-value-prop">
                <div className="value-prop-content">
                    <div className="main-message">
                        <h1 style={{ lineHeight: 1.2 }}>
                            ¡Enfócate en lo importante!{' '}
                            <span className="text-primary" style={{ lineHeight: 1.2 }}>
                                Nosotros llevamos el botellón a tu puerta
                            </span>
                        </h1>
                        <p className="main-subtitle">
                            <strong>No pierdas el ritmo</strong>. No dejes que la falta de agua
                            interrumpa tu concentración y trabajo.
                            <br />
                            <br />
                            Con nuestro <strong>Servicio Express</strong> a domicilio, puedes hacer
                            el pedido rápidamente y recibirlo en tu casa/oficina en 30 minutos.
                        </p>
                        <div className="value-prop-buttons">
                            <button
                                className="button button-success"
                                onClick={() => window.scroll({ top: 2000, behavior: 'smooth' })}
                            >
                                Quiero pedir el botellón
                            </button>
                        </div>
                    </div>
                    <div className="main-photo-primera-recarga"></div>
                </div>
            </section>
            <br />
            <br />
            <section className="primera-recarga-product-benefits">
                <div
                    className="primera-recarga-product-benefits-group"
                    style={{ marginTop: '1em' }}
                >
                    <div className="primera-recarga-product-benefit-1-tagline">
                        <strong>
                            ¿Cansado/a de salir a comprar el botellón?
                            <br />
                            <span className="text-primary">Yo también lo estaba </span>
                        </strong>{' '}
                        <div>
                            <p>
                                Hola, mi nombre es{' '}
                                <a
                                    href="https://www.linkedin.com/in/romer-maldonado/"
                                    target="_blank"
                                    style={{
                                        textDecorationLine: 'underline',
                                        color: '#00a4d3',
                                        fontWeight: 'bold',
                                    }}
                                    rel="noreferrer"
                                >
                                    Romer
                                </a>{' '}
                                y te voy a contar por qué este servicio{' '}
                                <strong>te puede servir</strong>.
                            </p>
                            <p>
                                Soy programador y cuando estaba muy ocupado en mi trabajo,
                                casualmente, <strong>se acababa el agua del botellón</strong>, por
                                lo cual tenia que salir con el envase vacío, comprar el botellón y
                                volver nuevamente, lo cual era <strong>bastante fastidioso</strong>.
                            </p>
                            <p>
                                Sé de primera mano que salir a comprar el botellón de agua puede ser{' '}
                                <strong>una pérdida de tiempo</strong>.
                            </p>{' '}
                            <p>
                                Por lo tanto, como proyecto personal, decidí crear un{' '}
                                <strong>servicio a domicilio y lancé una app</strong> para ayudar a
                                personas que tenian el mismo problema que yo.{' '}
                            </p>
                            <p>
                                <strong>Tú también</strong> tienes la oportunidad de quitarte el
                                estrés y la molestia de salir a comprar el botellón. Y no sólo eso,
                                sino que ahora disfrutarás de una{' '}
                                <strong>comodidad y conveniencia</strong> inigualable.
                            </p>
                        </div>
                    </div>
                    <div className="primera-recarga-product-benefit-1-image"></div>
                </div>
            </section>

            <section className="testimonials primera-recarga-testimonials">
                <div style={{ marginTop: 8, marginBottom: 8 }}>
                    <img
                        src="https://seoconf2024.com/wp-content/uploads/2022/01/LOGO-SEO-CONF_Mesa-de-trabajo-1-1.png"
                        height={80}
                    />
                </div>
                {/* <h1 style={{ padding: '0 28px', marginBottom: 0, lineHeight: 1.15 }}>
                    Venta de botellones{' '}
                    <span style={{ color: '#1b262c', lineHeight: 1.15 }}>hay muchas,</span>
                    <br />
                    que te lo lleven a domicilio en 30 minutos{' '}
                    <span style={{ color: '#1b262c', lineHeight: 1.15 }}>muy pocos.</span>
                </h1>
                <div className="testimonial-paragraph" style={{ padding: '0 28px' }}>
                    <h4>San Cristóbal, Táchira 🇻🇪</h4>
                </div> */}
            </section>
            {/* <Newsletter /> */}
            <section className="cta primera-recarga-cta">
                <h1 style={{ color: '#00a4d3' }}>Disfruta nuestro servicio!</h1>
                <div>
                    <p style={{ fontSize: '1rem' }}>
                        Por participar en la{' '}
                        <strong>
                            <u>
                                <a href="https://seoconf2024.com">SEOCONF 2024</a>
                            </u>
                        </strong>{' '}
                        disfruta el <strong>50% de descuento</strong> en tu primer servicio. Pagarás
                        6.000 pesos ó 1.50 dólares.
                        <br />
                        <br />
                        <strong>¿Qué incluye?</strong>
                        <br />
                        Te llevamos el botellón con agua y nos entrega tu envase vacío.
                        <br />
                        <span style={{ fontSize: 12 }}>*Delivery incluído</span>
                        <br />
                        <br />
                    </p>
                    {/* <form onSubmit={addEmailToList} className="primera-recarga-form-email">
                        <Input
                            type="text"
                            placeholder="Tu nombre"
                            value={state.name || ''}
                            onChange={e =>
                                setState({ ...state, error: null, name: e.target.value })
                            }
                            disabled={state.loading || state.resolved}
                        />
                        <Input
                            type="email"
                            placeholder="Tu email"
                            value={state.email || ''}
                            onChange={e =>
                                setState({ ...state, error: null, email: e.target.value })
                            }
                            disabled={state.loading || state.resolved}
                        />
                        <Button
                            type="submit"
                            className="button button-success"
                            label="Quiero mi cupón"
                            onClick={addEmailToList}
                            disabled={state.loading || state.resolved}
                        />
                    </form> */}
                    <a
                        href="https://wa.me/584147353821?text=Hola%20aguaman.%20Quiero%20canjear%20mi%20cupón%20de%20Descuento%20(SEOCONF24)"
                        className="button button-success"
                        target="_blank"
                        disabled={state.loading || state.resolved}
                    >
                        Quiero usar el cupón
                    </a>
                    <div>
                        <p style={{ color: 'green', fontWeight: 'bold' }}>
                            {state.resolved &&
                                '✅ Cupón enviado con éxito. Revisa la bandeja de tu correo electrónico.'}
                        </p>
                        {state.loading && <Loader />}
                        <p style={{ fontSize: '1rem' }}>{state.error}</p>
                    </div>
                    <br />
                    {/* <Link to="/botellon-nuevo">
                        <p style={{ textDecoration: 'underline', fontSize: '1rem' }}>
                            ¿No tienes envase? Presiona aquí
                        </p>
                    </Link> */}
                </div>
            </section>
        </Layout>
    );
};

export default PrimeraRecargapage;
